.hello {
  position: relative;
  background-image: url('../../../public/birmingham-museums-trust-B5evCyxk9Uo-unsplash.jpg');
  color: transparent;
  --webkit-background-clip: text;
  background-clip: text;
  background-position: top center;
  text-shadow: 5px 10px;
  transform: perspective(1000px) rotateX(10deg);
  width: 100%;
  animation: flow 10s ease-in-out infinite;
  z-index: -1;
}

@keyframes flow {
  0% {
    background-position: top center;
  }
  50% {
    background-position: bottom center;
  }
  100% {
    background-position: top center;
  }
}

.sentence {
  font-weight: 700;
  flex-shrink: 0;
  font-size: 80px;
  overflow: hidden;
  text-shadow:
    #0eb0c9 0 0 10px,
    #0eb0c9 0 0 20px,
    #0eb0c9 0 0 30px,
    #0eb0c9 0 0 40px;
}

.sentence[aria-label='im'] {
  position: relative;
  animation: slide 3s ease-in infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(20px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.card-container > div:nth-child(1) {
  transform: translate(75%, 0);
}

.card-container > div:nth-child(2) {
  transform: translate(25%, 0);
}

.card-container > div:nth-child(3) {
  transform: translate(100%, 0);
}

.card-container > div:hover {
  text-shadow:
    #0eb0c9 0 0 10px,
    #0eb0c9 0 0 20px,
    #0eb0c9 0 0 30px,
    #0eb0c9 0 0 40px;
}

.hobby-container > div:nth-child(2),
.hobby-container > div:nth-child(3) {
  margin-top: 4rem;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
