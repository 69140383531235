.blog-overview > div::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../../public/cloud-solid.svg') no-repeat;
  background-size: cover;
  transform: translateY(-15px);
}

.blog-title::after {
  padding-top: 0;
  content: '';
  display: block;
  line-height: 1rem;
  height: 2px;
  background-color: black;
  margin-bottom: 1rem;
}
